<template>
  <div class="home">
    <van-search
      @change="reload"
      v-model="keyword"
      show-action
      placeholder="请输入搜索关键词"
      input-align="center"
    >
      <template #label>
        <div style="display: flex;align-items: center;height: 100%;" @click="goAddress">
          <span style="font-size: 12px;">{{city}}</span>
          <van-icon name="arrow-down" />
          <div style="height: 50%;margin-left: 5px;width: 1px;background-color: #E2E1E1;"></div>
        </div>
      </template>
      <template #action>
        <div style="display: flex;align-items: center;" @click="goMap()">
          <van-icon name="location-o" />
          <span>地图</span>
        </div>
      </template>
    </van-search>
    <div class="home-top">
      <van-swipe :autoplay="3000" class="home-swipe" :show-indicators="false">
        <van-swipe-item v-for="(image, index) in images" :key="index">
          <img v-lazy="image.image" />
        </van-swipe-item>
      </van-swipe>
      <div class="home-classify">
        <img
          :src="img.img"
          alt
          v-for="(img,ins) in classifyImgs"
          :key="ins"
          @click="goSecondIndex(img.path) "
        />
      </div>
    </div>
    <div class="home-middle">
      <div class="home-selected">
        <div class="selected-title">
          <span>购物精选</span>
          <p class="title-right" @click="shoppingChoicen">点击查看更多超值精选好物</p>
        </div>
        <div class="selected-content">
          <div
            class="selected-item"
            v-for="(item,ins) in jingxuanList"
            :key="ins"
            @click="indexDetails(item.id)"
          >
            <img :src="item.image" alt />
            <p>{{item.name}}</p>
            <span>
              ￥
              <span class="item-price">{{item.price}}</span>
            </span>
          </div>
        </div>
      </div>

      <van-swipe :autoplay="3000" class="home-middle-swipe">
        <van-swipe-item v-for="(image, index) in middleImgs" :key="index">
          <img v-lazy="image.image" />
        </van-swipe-item>
      </van-swipe>

      <div class="home-coupon">
        <div class="coupon-title">
          <span class="coupon-title-left">优惠好券</span>
          <span class="coupon-title-right" @click="moreCoupon">更多推荐 ></span>
        </div>
        <div class="coupon-content">
          <div class="content-list" v-for="(item,ins) in coupon" :key="ins">
            <div class="content-title">满{{Math.round(item.consumer_full_money)}}元可用</div>
            <div class="content-moeny">
              <text>{{item.consumer_reduce_money}}</text>
              <img src="../../assets/coupon/moeny.png" alt />
            </div>
            <div class="content-get" v-if="item.is_take == 1" @click="getTake(item)">立即领取</div>
            <div class="content-get1" v-else>立即领取</div>
          </div>
        </div>
      </div>

      <div class="home-recommend">
        <div class="recommend-title">
          <span class="recommend-title-left">游玩推荐</span>

          <span class="recommend-title-right" @click="moreTravel">更多推荐 ></span>
        </div>
        <div class="recommend-content">
          <div
            class="recommend-item"
            v-for="(item,index) in youwanList"
            :key="index"
            @click="indexDetails(item.id)"
          >
            <img :src="item.image" alt />
            <div class="recommend-item-rank">
              <span>TOP{{index+1}}</span>
              <div></div>
            </div>
            <div class="recommend-item-content">
              <p class="recommend-item-title">{{item.name}}</p>
              <span>
                ￥
                <span class="item-price">{{item.price}}</span>
              </span>
              <!-- <div class="recommend-item-rate">
								<van-rate v-model="rateValue" :size="15" color="#FB9A00" void-icon="star"
									void-color="#eee" />
								<span>4.8分</span>
              </div>-->
              <!-- <span class="recommend-item-comment">15248条评论</span> -->
            </div>

            <!-- <van-rate v-model="rateValue" /> -->
          </div>
        </div>
      </div>
      <div class="home-recommend-bottom">
        <div class="recommend-bottom-item">
          <div class="recommend-bottom-title">
            <span>美食推荐</span>
          </div>
          <div class="recommend-bottom-content">
            <div
              class="recommend-bottom-content-item"
              v-for="(item,index) in meishiList.slice(0,2)"
              :key="index"
              @click="indexDetails(item.id)"
            >
              <img :src="item.image" alt />
              <div class="recommend-item-bottom-rank">
                <span>TOP{{index+1}}</span>
              </div>
              <p class="recommend-item-bottom-title">{{item.name}}</p>
            </div>
          </div>
        </div>
        <div class="recommend-bottom-item">
          <div class="recommend-bottom-title">
            <span>精品酒店</span>
          </div>
          <div class="recommend-bottom-content">
            <div
              class="recommend-bottom-content-item"
              v-for="(item,index) in holtGood.slice(0,2)"
              :key="index"
              @click="indexDetails(item.id)"
            >
              <img :src="item.image" alt />
              <div class="recommend-item-bottom-rank">TOP{{index+1}}</div>
              <p class="recommend-item-bottom-title">{{item.name}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="home-tab">
        <van-tabs v-model:active="active" sticky @click="toggle">
          <van-tab
            v-for="(item,ins) in tabTitles"
            :key="ins"
            :title="item.name"
            :name="item.type"
            style="width: 100%;"
          >
            <waterFall :list="tabContentList" v-if="tabContentList.length>0"></waterFall>
          </van-tab>
        </van-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import * as index from "@/api/index";
import waterFall from "../../components/waterFall.vue";
import { reactive } from "vue";
import { mapState } from "vuex";
import { toUrl } from "@/utils/tools";
import { Dialog, Toast } from "vant";
import { couponLst, couponTake } from "../../api/coupon";
export default {
  computed: {
    ...mapState(["city"]),
  },
  components: {
    waterFall,
  },
  data() {
    return {
      youwanList: [],
      meishiList: [],
      tabContentList: [],
      tabTitles: [
        {
          name: "附近推荐",
          type: "all",
        },
        {
          name: "精选超市",
          type: 0,
        },
        {
          name: "发现美食",
          type: 1,
        },
        {
          name: "文旅游玩",
          type: 2,
        },
      ],
      active: 0,
      showIndexBar: false,
      rateValue: 3,
      value: "",
      options: "",
      nullTip: "空空如也~",
      limit: 10, // 每页条数
      page: 1, // 页码
      type: 1,
      isDownLoading: false, // 下拉刷新
      isUpLoading: false, // 上拉加载
      upFinished: false, // 上拉加载完毕
      offset: 100, // 滚动条与底部距离小于 offset 时触发load事件
      value2: 0,
      value1: 0,
      dataList: "",
      keyword: "",
      images: [],
      middleImgs: [],
      classifyImgs: [
        {
          img: require("../../assets/homeClassifyImg1.png"),
          path: "/h5/play/index",
        },
        {
          img: require("../../assets/homeClassifyImg2.png"),
          path: "/h5/shopping/index",
        },
        {
          img: require("../../assets/homeClassifyImg3.png"),
          path: "/h5/foods/index",
        },
      ],
      jingxuanList: [],
      holtGood: [],
      coupon: [],
    };
  },
  computed: {
    location: function () {
      return this.$store.state.app.latAndLng;
    },
    city: function () {
      if (this.$store.state.app.city.length > 0) {
        return this.$store.state.app.city[0].name;
      } else {
        return this.$store.state.app.city.city;
      }
    },
  },

  mounted() {
    // this.city = this.$store.state.app.city[0].name

    console.log(JSON.stringify(this.$store.state.app));
    console.log(this.city);
    this.getSlideshow();
    this.getSlideshows();
    this.getShopRecom();
    this.getTravelRecom();
    this.getFoodRecom();
    this.getHotelRecom();
    this.getProductLst();
    this.couponLst();
  },

  methods: {
    couponLst() {
      let param = {
        type: "all",
        page: 1,
        limit: 4,
        is_hide: 1,
      };
      couponLst(param).then((res) => {
        console.log(res);
        this.coupon = res.data.list;
      });
    },
    getTake(item) {
      let param = {
        id: item.id,
      };
      couponTake(param).then((res) => {
        console.log(res);
        Toast(res.msg);
        this.couponLst();
      });
    },
    goAddress() {
      this.$router.push({
        path: "/h5/address",
      });
    },
    reload() {
      this.getProductLst();
    },
    toggle(name) {
      console.log(name);
      this.active = name;
      console.log(this.active);
      this.page = 1;
      this.tabContentList = [];
      this.getProductLst();
    },
    // 美食推荐
    foodRecomm() {
      this.$router.push({
        path: "/h5/foodDetail",
      });
    },
    // 附件推荐详情
    indexDetails(id) {
      console.log(id);
      this.$router.push({
        path: "/h5/commDetail",
        query: {
          id: id,
        },
      });
    },
    goSecondIndex(i) {
      this.$router.push({
        path: i,
      });
    },

    goMap() {
      this.$router.push({
        path: "/h5/map",
      });
    },
    // 轮播图
    getSlideshow() {
      let params = {
        page: 0,
        position: 0,
      };
      index.getSlideshow(params).then((res) => {
        console.log(res);
        this.images = res.data;
      });
    },
    getSlideshows() {
      let params = {
        page: 0,
        position: 1,
      };
      index.getSlideshow(params).then((res) => {
        console.log(res);
        this.middleImgs = res.data;
      });
    },
    // 购物精选
    getShopRecom() {
      let params = {
        page: this.page,
        limit: this.limit,
      };
      index.getShopRecom(params).then((res) => {
        // console.log(res)
        this.jingxuanList = res.data.data;
      });
    },
    // 购物精选更多
    shoppingChoicen() {
      this.$router.push({
        path: "/h5/shoppingChoicen",
      });
    },
    // 游玩推荐
    getTravelRecom() {
      let params = {
        page: this.page,
        limit: this.limit,
      };
      index.getTravelRecom(params).then((res) => {
        // console.log(res)
        this.youwanList = res.data.data;
      });
    },
    moreCoupon() {
      this.$router.push({
        path: "/h5/couponCentre",
        query: {
          type: 0,
        },
      });
    },
    // 游玩推荐更多
    moreTravel() {
      this.$router.push({
        path: "/h5/moreTravel",
      });
    },
    // 美食推荐
    getFoodRecom() {
      let params = {
        page: this.page,
        limit: this.limit,
      };
      index.getFoodRecom(params).then((res) => {
        // console.log(res)
        this.meishiList = res.data.data;
      });
    },
    // 精品酒店
    getHotelRecom() {
      let params = {
        page: this.page,
        limit: this.limit,
      };
      index.getHotelRecom(params).then((res) => {
        // console.log(res)
        this.holtGood = res.data.data;
      });
    },
    // 商品列表
    getProductLst() {
      this.tabContentList = [];
      let params = {
        page: this.page,
        limit: this.limit,
        keyword: this.keyword,
        cate_type: this.active,
        lng: this.location.longitude,
        lat: this.location.latitude,
      };
      index.getProductLst(params).then((res) => {
        console.log(res);
        this.tabContentList = this.tabContentList.concat(res.data.data);
        // console.log(this.tabContentList)
      });
    },
    // 酒店精选
    siftHotel() {
      this.$router.push({
        path: "/h5/hotelDetails",
      });
    },
    // 游玩推荐
    secnic() {
      this.$router.push({
        path: "/h5/secnicDetails",
      });
    },

    // 上拉加载请求方法
    onLoad() {
      console.log(this.page);
      this.page++;
      this.getProductLst();
    },
  },
};
</script>
<style lang="less" scoped>
@import "~@/assets/style/preset.less";

.home {
  // overflow-y: scroll;
  // height: calc(100% - 50px);
  background-color: #f6f6f6;

  .home-swipe {
    width: 100%;
    height: 120px;
    border-radius: 5px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .home-top {
    padding: 0 15px;
    background-color: #ffffff;
    box-sizing: border-box;
    width: 100%;

    .home-classify {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 10px 0;
      box-sizing: border-box;

      img {
        width: 102px;
        height: 57px;
        border-radius: 5px;
      }
    }
  }

  .home-middle {
    padding: 0 15px;
    width: 100%;
    box-sizing: border-box;

    .home-middle-swipe {
      height: 105px;
      border-radius: 5px;
      margin-top: 10px;

      ::v-deep .van-swipe__indicator {
        border-radius: 0;
        width: 5px;
        height: 3px;

        background-color: #ffffff;
      }

      ::v-deep .van-swipe__indicator--active {
        border-radius: 0;
        width: 10px;
        height: 3px;

        background-color: #ffffff;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    .home-selected {
      background-color: #ffefcd;
      padding-bottom: 10px;
      width: 100%;
      border-radius: 5px;
      margin-top: 10px;

      .selected-title {
        display: flex;
        width: 100%;
        justify-content: space-between;
        font-size: 16px;
        position: relative;

        span {
          padding: 5px 10px;
          font-size: 14px;
          font-weight: bold;
          line-height: 20px;
        }

        .title-right {
          background-color: #fffcf5;
          margin: 0;
          padding: 5px 10px;
          width: 65%;
          position: absolute;
          right: 0;
          font-size: 10px;
          color: #5a4420;
          line-height: 20px;
          border-top-right-radius: 10px;
        }

        .title-right::after {
          content: " ";
          position: absolute;
          top: 0;
          left: -8px;
          border-width: 30px 8px;

          border-style: solid;

          border-color: #fffcf5 transparent transparent transparent;
        }
      }

      .selected-content {
        display: flex;
        width: 100%;
        margin-top: 5px;
        overflow-x: auto;
        justify-content: space-between;

        .selected-item {
          width: 28%;
          display: flex;
          flex-shrink: 0;
          flex-direction: column;
          margin-left: 5px;
          background-color: #ffffff;
          border-radius: 5px;
          padding: 10px;
          box-sizing: border-box;

          p {
            font-size: 14px;
            margin: 0;

            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
          }

          span {
            font-size: 12px;

            .item-price {
              font-size: 14px;
              color: #fc6228;
              font-weight: bold;
            }
          }

          img {
            width: 68px;
            height: 68px;
            border-radius: 2px;
          }
        }
      }
    }
    .home-coupon {
      background-color: #ffffff;
      border-radius: 5px;
      margin-top: 10px;
      padding: 5px;
      .coupon-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px;

        .coupon-title-left {
          font-size: 14px;
          font-weight: bold;
        }

        .coupon-title-right {
          font-size: 12px;
          color: #999999;
        }
      }
      //
      .coupon-content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: 15px 0;
        .content-list {
          background-image: url(../../assets/coupon/indexC.png);
          background-size: 100% auto;
          width: 75px;
          height: 90px;
          // box-shadow: 0px 1px 1px 0px rgba(65, 63, 63, 0.38);
          display: flex;
          flex-direction: column;
          align-items: center;
          .content-title {
            font-size: 10px;
            font-weight: bold;
            color: #f84214;
            padding-top: 12px;
            transform: scale(calc(10 / 12));
          }
          .content-moeny {
            font-size: 35px;
            font-weight: bold;
            color: #fb421a;
            display: flex;
            img {
              width: 9px;
              height: 8px;
            }
          }
          .content-get {
            // background-image: url(../../assets/coupon/indexJ.png);
            // background-size: 100% auto;
            background: linear-gradient(8deg, #ffffff, #f7c559);
            width: 60px;
            height: 17px;
            font-size: 12px;
            border-radius: 10px;
            text-align: center;
            color: #fb421a;
            transform: scale(calc(10 / 12));
          }
          .content-get1 {
            background: #cecece;
            width: 60px;
            height: 17px;
            font-size: 12px;
            border-radius: 10px;
            text-align: center;
            color: #fb421a;
            transform: scale(calc(10 / 12));
          }
        }
      }
    }

    .home-recommend {
      background-color: #ffffff;
      border-radius: 5px;
      margin-top: 10px;
      padding: 5px;

      .recommend-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px;

        .recommend-title-left {
          font-size: 14px;
          font-weight: bold;
        }

        .recommend-title-right {
          font-size: 12px;
          color: #999999;
        }
      }

      .recommend-content {
        display: flex;
        width: 100%;
        margin-top: 5px;
        overflow-x: auto;
        justify-content: space-between;

        .recommend-item {
          width: 130px;
          position: relative;
          display: flex;
          flex-shrink: 0;
          flex-direction: column;
          margin-left: 5px;
          background-color: #ffffff;

          box-sizing: border-box;

          img {
            width: 100%;
            height: 85px;
            border-radius: 5px;
          }

          span {
            font-size: 12px;

            .item-price {
              font-size: 14px;
              color: #fc6228;
              font-weight: bold;
            }
          }

          .recommend-item-rank {
            background-color: #ff4342;
            width: 60px;
            height: 23px;
            position: absolute;
            top: 0;
            left: 0;
            border-bottom-right-radius: 20px;
            border-top-left-radius: 5px;

            span {
              color: #ffffff;
              font-size: 14px;
              position: absolute;
              top: 3px;
              left: 5px;
            }

            div {
              height: 11px;
              width: 5px;
              position: absolute;
              right: -5px;
              top: 0;
            }

            div::after {
              content: " ";
              position: absolute;
              top: 0;
              right: 0;
              border-width: 14px 8px;
              border-style: solid;
              border-color: #ff4342 transparent transparent transparent;
            }
          }

          .recommend-item-content {
            padding: 0 5px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            .recommend-item-rate {
              display: flex;
              align-items: center;
              margin-bottom: 5px;

              span {
                font-size: 10px;
                color: #fb9a00;
              }
            }

            .recommend-item-title {
              font-size: 14px;
              color: #1a1a1a;
              margin: 6px 0 3px 0;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .recommend-item-comment {
              font-size: 10px;
              color: #999999;
            }
          }
        }
      }
    }

    .home-recommend-bottom {
      display: flex;
      align-items: center;
      margin-top: 10px;
      justify-content: space-between;

      .recommend-bottom-item {
        border-radius: 5px;
        background-color: #ffffff;
        width: 48%;

        .recommend-bottom-title {
          font-size: 14px;
          font-weight: bold;
          padding: 5px;
        }

        .recommend-bottom-content {
          display: flex;
          padding: 0 8px;
          justify-content: space-between;

          .recommend-bottom-content-item {
            position: relative;
            width: 48%;

            img {
              width: 73px;
              height: 73px;
            }
          }
        }

        .recommend-item-bottom-rank {
          background-color: #fc6228;
          position: absolute;
          top: 0;
          left: 0;
          width: 43px;
          height: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ffffff;
          font-size: 10px;
        }

        .recommend-item-bottom-title {
          font-size: 12px;
          color: #1a1a1a;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: 0;
          position: relative;
          top: -10px;
        }
      }
    }
  }

  .home-tab {
    height: 100%;
    width: 100%;

    // padding: 10px;
    ::v-deep .van-tabs__nav {
      background-color: #f6f6f6;
    }

    ::v-deep .van-tabs__line {
      width: 20px;
      background-color: #fb6228;
      bottom: 22px;
    }

    .v-waterfall-content {
      width: 100%;
      height: 100%;
      position: relative;
      box-sizing: border-box;
    }

    .v-waterfall-item {
      position: absolute;
      border-radius: 10px;
      background-color: #f2270c;
    }

    .v-waterfall-item img {
      width: 100%;
      height: 100%;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }
}
</style>
